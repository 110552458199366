<template>
  <div class="brand">
    <subtitle>
      <div slot="above-title">品牌实力</div>
    </subtitle>
    <div class="brand_content">
      <tab-control-btn>
        <tab-control-panel label="大富智慧健康" name="大富智慧健康">
          <img
            src="~assets/img/home/brand1.png"
            width="100%"
            height="100%"
            alt=""
          />
        </tab-control-panel>

        <tab-control-panel label="智能设备" name="智能设备">
          <div class="content_device">
            <el-carousel :interval="3000" type="card" height="500px">
              <el-carousel-item>
                <div class="device_tip" style="background: #e95d00">2017</div>
                <div class="device_title">2017年 便捷式经络检测仪问世</div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="device_tip" style="background: #c801b3">2018</div>
                <div class="device_title">2018年 智能血压脉象仪问世</div>
              </el-carousel-item>
              <!-- <el-carousel-item>
                <div class="device_tip" style="background: #805ac7">2020</div>
                <div class="device_title">2020年 第二代艾灸机器人诞生</div>
              </el-carousel-item> -->
            </el-carousel>
          </div>
        </tab-control-panel>

        <!-- <tab-control-panel label="加盟合作" name="加盟合作">
          <div style="position: relative">
            <img src="~assets/img/home/brand2.png" width="100%" height="100%" />
            <div class="content_join">
              <div class="join_title">加盟优势：整店支持体系</div>
              <div style="display: flex; margin-top: 20px">
                <div class="join_circle_text">智慧<br />理疗</div>
                <div class="join_circle_text">智能<br />设备</div>
                <div class="join_circle_text">社区<br />合作</div>
                <div class="join_circle_text">灵活<br />模式</div>
              </div>
              <div style="display: flex; margin-top: 40px; line-height: 28px">
                <div class="join_title">加盟模式：</div>
                <div class="join_line_text">
                  店面运营模式，选址科学，形象统一 ，产品齐全，<br />投资成本低，市场空间大，运作简单，客流充足。
                </div>
              </div>
              <div style="display: flex; margin-top: 30px; line-height: 28px">
                <div class="join_title">渠道开拓模式：</div>
                <div class="join_line_text">
                  无需店面，直接销售产品，模式灵活，迅速拓展。
                </div>
              </div>
              <div style="display: flex; margin-top: 30px; line-height: 28px">
                <div class="join_title">区域独家代理：</div>
                <div class="join_line_text">
                  独享地区的养生理疗市场，可在市、县、区<br />享受独家代理。
                </div>
              </div>
            </div>
          </div>
        </tab-control-panel> -->
      </tab-control-btn>
    </div>
  </div>
</template>

<script>
import Subtitle from 'components/content/subtitle/Subtitle'
import TabControlBtn from 'components/content/tabControl/tabControlBtn/TabControlBtn'
import TabControlPanel from 'components/content/tabControl/TabControlPanel'

export default {
  name: 'Brand',
  components: {
    Subtitle,
    TabControlBtn,
    TabControlPanel
  }
}
</script>

<style scoped>
.brand {
  padding: 50px 0;
  background-image: url('~assets/img/common/bgc.png');
}

.brand_content {
  margin-top: 30px;
}

.content_device {
  padding: 35px 40px 0px;
}
.device_title {
  line-height: 500px;
  font-size: 24px;
  color: #848484;
}
.device_tip {
  position: absolute;
  left: -60px;
  height: 60px;
  width: 250px;
  line-height: 60px;
  background-color: #f7d100;
  transform: rotate(-36deg);
  color: #fff;
  font-size: 28px;
  text-align: left;
  padding-left: 75px;
}

.content_join {
  position: absolute;
  z-index: 9;
  left: 6%;
  top: 16%;
}
.join_title {
  font-size: 18px;
  color: #951e23;
  font-weight: bold;
  text-align: left;
}
.join_circle_text {
  height: 86px;
  width: 86px;
  border-radius: 50%;
  border: 1px solid #dcdcdc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #6c6c6c;
  margin: 0 10px;
}
.join_line_text {
  font-size: 18px;
  color: #6c6c6c;
  text-align: left;
}

.el-carousel__item {
  background-color: #fff;
  border: 1px solid #bababa;
  color: #475669;
}
</style>
