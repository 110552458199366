<template>
  <div class="foot">
    <div class="div-left">
      <div>
        <p>
          公司官网：深圳市大富智慧健康科技有限公司
          <a style="color: #bbbbbb" href="http://www.sktcm.com"
            >www.sktcm.com</a
          >
        </p>
        <p>联系电话：0755-29816880</p>
        <p>联系地址：深圳市宝安区沙井街道蚝一社区锦程路大富厂13栋二层</p>
        <p>邮政编码：518104</p>
        <p class="record">
          深圳市大富智慧健康科技有限公司版权所有©2015-{{ year }}
          <a style="color: #8d8d8d" href="https://beian.miit.gov.cn"
            >粤ICP备14053417号</a
          >
        </p>
      </div>
    </div>
    <!-- <div class="div-left">
      <div>
        <p>
          公司官网：深圳市大富智慧健康科技有限公司
          <a style="color: #bbbbbb" href="http://www.sktcm.com"
            >www.sktcm.com</a
          >
        </p>
        <p class="record">
          深圳市大富智慧健康科技有限公司版权所有©2015-{{ year }}
          <a style="color: #8d8d8d" href="https://beian.miit.gov.cn"
            >粤ICP备14053417号</a
          >
        </p>
      </div>
    </div> -->

    <div class="div-right">
      <img src="~assets/img/common/QRcode.png" alt="" />
      <div class="saoma">扫码关注官方公众号</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Foot',
  data() {
    return {
      year: undefined
    }
  },
  mounted() {
    this.year = new Date().getFullYear()
  }
}
</script>

<style scoped>
.foot {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background-color: #3b3b3c;
  padding: 60px 160px;
  width: 100%;
  min-width: 1220px;
}

.div-left {
  color: #fff;
  font-size: 14px;
}

.div-left p {
  margin: 15px 0;
}

.div-left p.record {
  padding-top: 10px;
  border-top: 1px solid #aaa;
}

.saoma {
  color: #fff;
  font-size: 15px;
  text-align: center;
  margin-top: 5px;
}
</style>
